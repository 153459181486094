<div class="feature-passwordless-auth tw-flex tw-items-center tw-justify-center tw-h-full">
  @if (isAPIInProgress()) {
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <p-progressSpinner styleClass="spinner-50" strokeWidth="5"></p-progressSpinner>
      <h3 class="tw-text-text-primary tw-font-500">Signing in...</h3>
    </div>
  } @else {
    <div class="auth-container tw-items-center tw-justify-center">
      <div class="card-container">
        <header class="tw-flex tw-justify-center tw-items-center tw-mb-40-px tw-w-full">
          <a href="#">
            <img src="{{iconUrl()}}" aria-label="Fyle Logo" alt="Fyle" class="brand-logo tw-h-30-px">
          </a>
        </header>      
        <div class="auth-card tw-items-center">
          <div class="icon-bg tw-flex tw-items-center tw-justify-center tw-h-40-px tw-w-40-px tw-rounded-border-radius-2xs tw-bg-bg-brand-muted">
            <ui-svg-sprite 
              [icon]="'danger-outline'"
              [containerClasses]="'tw-flex'"
              [classes]="'tw-h-16-px tw-w-16-px tw-fill-icon-danger'">
            </ui-svg-sprite>
          </div>
          <h3 class="header">{{ headerText() }}</h3>
          <div class="description tw-text-center">
            {{ descriptionText() }}
          </div>
        </div>
      </div>
    </div>
  }
    
</div>