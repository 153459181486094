import { Inject, Injectable } from '@angular/core';
import {
  AuthPayload,
  AuthResponse,
  MagicLinkSigninPayload,
  ResetPasswordPayload,
  SignInPayload,
  SignInResponse,
} from '@fyle/model-shared';
import { OrgStorageService } from '@fyle/org-storage';
import { ROOT_URL } from '@fyle/root-url-token';
import { RouterApiService } from '@fyle/router-api';
import { TabStorageService } from '@fyle/tab-storage';
import { TokenService } from '@fyle/token';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class RouterAuthService {
  constructor(
    private routerApiService: RouterApiService,
    private orgStorageService: OrgStorageService,
    private tokenService: TokenService,
    private tabStorageService: TabStorageService,
    @Inject(ROOT_URL) private rootUrl: BehaviorSubject<string>,
  ) {}

  private getTrimmedEmail(email: string): string {
    return email.trim().toLowerCase();
  }

  private setClusterDomain(domain: string): void {
    this.rootUrl.next(domain);
    this.tokenService.setClusterDomain(domain);
  }

  private newRefreshToken(refreshToken: string): void {
    this.orgStorageService.remove('user');
    this.orgStorageService.remove('in_app_chat_restore_id');
    this.tokenService.setRefreshToken(refreshToken);
  }

  private fetchAccessToken(refreshToken: string): Observable<AuthResponse> {
    const payload: Pick<AuthPayload, 'refresh_token'> = {
      refresh_token: refreshToken,
    };
    return this.routerApiService.post<AuthResponse>('/auth/access_token', payload);
  }

  handleSignInResponse(data: SignInResponse): Observable<SignInResponse> {
    /* org_id is not required for API's like reset_password, etc */
    if (data.org_id) {
      this.tabStorageService.set('org_id', data.org_id);
    }
    this.newRefreshToken(data.refresh_token);
    this.setClusterDomain(data.cluster_domain);

    let obs$: Observable<SignInResponse> = {} as Observable<SignInResponse>;
    const accessToken = this.tokenService.getAccessToken();

    if (accessToken) {
      obs$ = of(data);
    } else {
      return this.fetchAccessToken(data.refresh_token).pipe(
        map((resp) => {
          this.tokenService.setAccessToken(resp.access_token);
          return data;
        }),
      );
    }

    return obs$;
  }

  basicSignin(email: string, password: string): Observable<SignInResponse> {
    const trimmedEmail = this.getTrimmedEmail(email);
    const payload: SignInPayload = {
      email: trimmedEmail,
      password,
    };
    return this.routerApiService.post<SignInResponse>('/auth/basic/signin', payload);
  }

  resetPassword(refreshToken: string, password: string): Observable<SignInResponse> {
    const payload: ResetPasswordPayload = {
      refresh_token: refreshToken,
      password,
    };
    return this.routerApiService.post<SignInResponse>('/auth/reset_password', payload);
  }

  handleMagicLinkToken(token: string): Observable<SignInResponse> {
    const payload: MagicLinkSigninPayload = {
      token: token,
    };
    return this.routerApiService
      .post<SignInResponse>('/auth/magic_link', payload)
      .pipe(switchMap((resp) => this.handleSignInResponse(resp)));
  }
}
