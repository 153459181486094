import { Injectable } from '@angular/core';
import { OrgStorageService } from '@fyle/org-storage';
import { UserStorageService } from '@fyle/user-storage';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    private orgStorageService: OrgStorageService,
    private userStorageService: UserStorageService,
  ) {}

  getAccessToken(): string {
    return this.orgStorageService.get('access_token');
  }

  getRefreshToken(): string {
    return this.orgStorageService.get('refresh_token');
  }

  setAccessToken(accessToken: string): void {
    this.orgStorageService.set('access_token', accessToken);
  }

  setRefreshToken(refreshToken: string): void {
    this.orgStorageService.set('refresh_token', refreshToken);
  }

  resetAccessToken(): void {
    this.orgStorageService.remove('access_token');
  }

  resetRefreshToken(): void {
    this.orgStorageService.remove('refresh_token');
  }

  setClusterDomain(clusterDomain: string): void {
    this.userStorageService.set('cluster_domain', clusterDomain);
  }

  getClusterDomain(): string {
    return this.userStorageService.get('cluster_domain');
  }

  isUserFromINCluster(): boolean {
    const userClusterDomain = this.getClusterDomain();
    return userClusterDomain.includes('in1.fylehq.com');
  }
}
