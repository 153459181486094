import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth-guard';
import { DisabledComponent } from './disabled/disabled.component';
import { GoogleSignInComponent } from './google-sign-in/google-sign-in.component';
import { LogoutComponent } from './logout/logout.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { PendingVerificationComponent } from './pending-verification/pending-verification.component';
import { RequestInvitationComponent } from './request-invitation/request-invitation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecondaryEmailComponent } from './secondary-email/secondary-email.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignupComponent } from './signup/signup.component';
import { SwitchOrgComponent } from './switch-org/switch-org.component';
import { VerifyComponent } from './verify/verify.component';
import { OrgService } from '../core/services/org.service';
import { CapitaloneSignonComponent } from './capitalone-signon/capitalone-signon.component';
import { FeatureMagicLinkSigninComponent } from '@fyle/accounts/magic-link-signin';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'disabled',
    component: DisabledComponent,
  },
  {
    path: 'google',
    component: GoogleSignInComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'new_password',
    component: NewPasswordComponent,
  },
  {
    path: 'pending_verification',
    component: PendingVerificationComponent,
  },
  {
    path: 'request_invitation',
    component: RequestInvitationComponent,
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent,
  },
  {
    path: 'secondary_email/verify',
    component: SecondaryEmailComponent,
  },
  {
    path: 'signin',
    component: SignInComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'switch_org',
    component: SwitchOrgComponent,
    resolve: {
      orgs: () => inject(OrgService).getOrgs(),
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'verify',
    component: VerifyComponent,
  },
  {
    path: 'capitalone_signon',
    component: CapitaloneSignonComponent,
  },
  {
    path: 'magic_link_signin',
    component: FeatureMagicLinkSigninComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
