import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequestOptions } from '@fyle/model-shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterApiService {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, config?: HttpRequestOptions): Observable<T> {
    const apiPath = `${environment.ROUTER_URL}/routerapi${url}`;
    return this.httpClient.get<T>(apiPath, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(url: string, body: Record<string, any> = {}, config?: HttpRequestOptions): Observable<T> {
    const apiPath = `${environment.ROUTER_URL}/routerapi${url}`;
    return this.httpClient.post<T>(apiPath, body, config);
  }
}
